<template>
  <section class="intelligence-send">
    <section class="filter">
      <div >
        <label class="title" style="position: relative;">情报生成时间：
          <el-radio-group v-model="form.releaseTime" size="mini" @change="changeHandler">
            <el-radio label="1" border>今天</el-radio>
            <el-radio label="2" border>最近三天</el-radio>
            <el-radio label="3" border>最近七天</el-radio>
            <el-radio label="4" border>最近三十天</el-radio>
            <el-radio label="5" border @click.native="pubDateStyle.display = 'inline-block'">自定义</el-radio>
            <el-date-picker v-model="form.pubDateFrame" type="datetimerange" size="mini" range-separator="--" unlink-panels
              start-placeholder="开始日期" end-placeholder="结束日期" :style="pubDateStyle" @blur="pubDateStyle.display = 'none'">
            </el-date-picker>
            <label class="title"  style="margin-left: 30px">关键词:
              <el-input  size="mini" placeholder="请输入关键词"  v-model="form.keyWords">
              </el-input>
            </label>
            <el-button type="primary" size="medium" class="search-btn" @click="search" :disabled="loading">查询</el-button>
          </el-radio-group>
        </label>
      </div>

    </section>
    <section class="content">
      <!-- <el-button size="mini" type="primary" @click="revoke">撤销</el-button> -->
      <el-table :data="tableData" border style="width: 100%" @selection-change="handleSelectionChange"
      v-loading="loading" element-loading-text="正在查询" max-height="calc(100% - 40px)">
        <el-table-column  :resizable="false" type="selection" width="40" align="center">
        </el-table-column>
        <el-table-column  :resizable="false" prop="type" label="类型" width="80" align="center">
          <template scope="{ row }">
            {{row.type === 0? '新闻':'简报'}}
          </template>
        </el-table-column>
         <el-table-column  :resizable="false" label="标题" >
           <template slot-scope="scope">
<!--             <el-link type="primary" v-if="scope.row.type === 0" :href="scope.row.newsList[0].url" target="_blank" :underline="false" v-html="scope.row.title"></el-link>-->
             <div class="dataTitle"  v-html="scope.row.title"></div>
           </template>
        </el-table-column>
        <el-table-column  :resizable="false" prop="createDate" label="时间" align="center" width="180">
        </el-table-column>
<!--        <el-table-column  :resizable="false" prop="status" label="状态" align="center" width="80">-->
<!--            <template scope="{row}">-->
<!--              {{row.status === 0? '未推送':'已推送'}}-->
<!--            </template>-->
<!--        </el-table-column>-->
        <el-table-column  :resizable="false" label="操作" width="180" align="center">
          <template scope="{ row }">
            <el-button type="text" @click="details(row.id,row.type)">查看详情</el-button>
            <el-button type="text" @click="revoke(row.id)">撤销</el-button>
          </template>
        </el-table-column>
      </el-table>
      <section class="list-bottom">
        <span class="count">共{{count}}条记录</span>
        <el-pagination :current-page="currentPage" :page-sizes="[10, 20, 30, 50]" :page-size="size" layout="total, sizes, prev, pager, next, jumper"
        @size-change="sizeChange" @current-change="currentChange" :total="count">
        </el-pagination>
      </section>
      <el-dialog title="提示" :visible.sync="revokeDialog" width="30%" :before-close="revokeClose">
        <span>确定撤销？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="revokeDialog = false">取 消</el-button>
          <el-button type="primary" @click="confirmRevoke()">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog title="情报详情" :visible.sync="dialogVisible" width="910px" class="details-wrap" :before-close="handleClose">
        <template>
          <div v-if="type == 0">
            <span class="title">{{lookNewsDetailData.title}}</span>
             <div class="details">
              <p>[新闻]</p>
              <p>标题：{{lookNewsDetailData.title}}</p>
              <p>摘要：{{lookNewsDetailData.summary}}</p>
              <p>网站：{{lookNewsDetailData.siteName}}</p>
              <p>网址：<el-link type="primary" :href="lookNewsDetailData.url" target="_blank" :underline="false">{{lookNewsDetailData.url}}</el-link></p>
            </div>
            <!--<el-table :data="tableData" border style="width: 100%">-->
              <!--<el-table-column  :resizable="false" prop="receiver" label="接收人" width="180">-->
              <!--</el-table-column>-->
              <!--<el-table-column  :resizable="false" prop="inbox" label="接受地址" width="180">-->
              <!--</el-table-column>-->
              <!--<el-table-column  :resizable="false"  label="发送状态" width="180">-->
              <!--</el-table-column>-->
              <!--<el-table-column  :resizable="false" prop="sendTime" label="发送时间">-->
              <!--</el-table-column>-->
            <!--</el-table>-->
          </div>
          <el-table :data="lookReportDetailData" border style="width: 100%" v-else>
            <el-table-column  :resizable="false" prop="title" label="标题" >
              <template slot-scope="scope">
                <el-link type="primary" :href="scope.row.url" target="_blank" :underline="false" v-html="scope.row.title"></el-link>
              </template>
            </el-table-column>
            <el-table-column  :resizable="false" prop="publishdateStd" label="时间" align="center" width="180">
            </el-table-column>
          </el-table>
        </template>
      </el-dialog>
    </section>
  </section>
</template>

<script>
import { listIntelligence, conditionQueryIntelligence, backoutIntelligence, queryIntelligenceDetail } from '@/api/intelligence/send'
export default {
  data () {
    return ({
      type: '',
      count: 0,
      currentPage: 1,
      size: 10,
      form: {
        releaseTime: '1',
        keyWords: '',
        pubDateFrame: [],
        data1: '',
        data2: ''
      },
      pubDateStyle: {
        display: 'none',
        margin: '0 15px'
      },
      tableData: [],
      lookReportDetailData: [{
        sn: '',
        title: '',
        publishdateStd: ''
      }],
      lookNewsDetailData: {
        sn: '',
        title: '',
        siteName: '',
        url: '',
        summary: ''
      },
      revokeDialog: false,
      dialogVisible: false,
      dataHtml: [],
      loading: false
    })
  },
  watch: {
    'form.pubDateFrame': {
      handler (val) {
        if (val.length > 0) {
          this.form.date1 = this.formatDate(val[0])
          this.form.date2 = this.formatDate(val[1])
          // this.datepickerStyle.display = 'none'
        }
      },
      deep: true,
      immediate: true
    }
  },
  created () {
    setTimeout(() => {
      var tableId = document.getElementsByTagName('table')[1]
      for (var i = 0; i < tableId.rows.length; i++) {
        this.dataHtml.push(tableId.rows[i].cells[2].innerHTML)
      }
    }, 1000)
  },
  methods: {
    // 格式化时间戳
    formatDate (time) {
      let date = new Date(time)
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      let hour = date.getHours()
      let min = date.getMinutes()
      let second = date.getSeconds()
      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        day = '0' + day
      }
      if (hour < 10) {
        hour = '0' + hour
      }
      if (min < 10) {
        min = '0' + min
      }
      if (second < 10) {
        second = '0' + second
      }
      return year + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + second
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
    },
    // 监听发布时间选中情况
    changeHandler () {
      if (this.form.releaseTime === '5') {
        this.pubDateStyle.display = 'inline-block'
      } else {
        this.pubDateStyle.display = 'none'
        this.form.pubDateFrame = []
      }
    },
    sizeChange: async function (i) {
      this.currentPage = 1
      this.size = i
      try {
        const res = await listIntelligence({
          size: this.size,
          currentPage: this.currentPage,
          'timeType': this.form.releaseTime,
          'keyword': this.form.keyWords,
          'push': 1,
          'startDate': this.form.date1,
          'endDate': this.form.date2
        })
        for (var i = 0; i < res.data.list.length; i++) {
          res.data.list[i].title = res.data.list[i].title.replace('【负面】', '<el-tag size="mini" effect="dark" style="background-color: #ED9090;border-color:#ED9090;height:19px;padding:0 3px;border-radius:2px;color: #FFF !important;margin-right: 5px">负</el-tag>')
        }
        this.tableData = res.data.list
        this.count = parseInt(res.data.count)
      } catch (err) {
        console.log(err)
      }
    },
    currentChange: async function (i) {
      this.currentPage = i
      console.log(this.form.releaseTime)
      const res = await listIntelligence({
        'size': this.size,
        'currentPage': this.currentPage,
        'timeType': this.form.releaseTime,
        'keyword': this.form.keyWords,
        'push': 1,
        'startDate': this.form.date1,
        'endDate': this.form.date2
      })
      for (var i = 0; i < res.data.list.length; i++) {
        res.data.list[i].title = res.data.list[i].title.replace('【负面】', '<el-tag size="mini" effect="dark" style="background-color: #ED9090;border-color:#ED9090;height:19px;padding:0 3px;border-radius:2px;color: #FFF !important;margin-right: 5px">负</el-tag>')
      }
      this.tableData = res.data.list
      this.count = parseInt(res.data.count)
    },
    handleClose () {
      this.dialogVisible = false
    },
    revokeClose () {
      console.log('hello')
    },
    async details (id, type) {
      this.type = type
      this.loading=true
      var list = await this.viewQueryIntelligenceDetail(id)
      if (type === 0) {
        this.lookNewsDetailData.sn = list[0].sn
        this.lookNewsDetailData.title = list[0].title
        this.lookNewsDetailData.siteName = list[0].siteName
        this.lookNewsDetailData.url = list[0].url
        this.lookNewsDetailData.summary = list[0].summary
        this.loading=false
        this.dialogVisible = true
        return
      } else {
        this.lookReportDetailData = list
        this.dialogVisible = true
        this.loading=false
        return
      }
      this.$message.info('暂无详细')
      this.dialogVisible = false
    },
    revoke (id) {
      this.intelligenceId = id
      this.revokeDialog = true
    },
    async confirmRevoke () {
      const res = await backoutIntelligence({ 'intelligenceId': this.intelligenceId })
      console.log(res)
      if (res.code === 0) {
        this.$message.success('撤销成功')
      } else {
        this.$message.error('撤销失败')
      }
      this.revokeDialog = false
      this.getListIntelligence()
    },
    async viewQueryIntelligenceDetail (id) {
      const res = await queryIntelligenceDetail({ 'id': id })
      return res.data.newList
    },
    // 根据条件查询，如果关键字存在，关键字标红
    async search () {
      this.loading = true
      this.currentPage = 1
      this.size = 10
      let params = {
        'timeType': this.form.releaseTime,
        'keyword': this.form.keyWords,
        'currentPage': this.currentPage,
        'size': this.size,
        'push': 1,
        'startDate': this.form.date1,
        'endDate': this.form.date2
      }
      const res = await conditionQueryIntelligence(params)
      if (res.code === 0 && res.data != null && res.data.count > 0) {
        for (var i = 0; i < res.data.list.length; i++) {
          res.data.list[i].title = res.data.list[i].title.replace('【负面】', '<el-tag size="mini" effect="dark" style="background-color: #ED9090;border-color:#ED9090;height:19px;padding:0 3px;border-radius:2px;color: #FFF !important;margin-right: 5px">负</el-tag>')
        }
        this.tableData = res.data.list
        this.count = res.data.count
      } else if (res.code === 0 && res.data != null) {
        this.tableData = []
        this.$message.info('暂无数据')
      } else if (res.code === 500) {
        this.$message.error(res.msg)
      }
      /* if (this.form.keyWords && this.form.keyWords !== '') {
       var regExp = new RegExp(this.form.keyWords, 'ig')
       var tableId = document.getElementsByTagName('table')[1]
       for (var i = 0; i < tableId.rows.length; i++) {
         var html = this.dataHtml[i]
         var newHtml = html.replace(regExp, '<span style="color: red;">' + this.form.keyWords + '</span>')
         tableId.rows[i].cells[2].innerHTML = newHtml
       }
     } */
      this.loading = false
    },
    async getListIntelligence () {
      this.loading = true
      const res = await listIntelligence({ 'currentPage': this.currentPage, 'size': this.size, 'push': 1, 'timeType': 1, 'startDate': null, 'endDate': null })
      if (res.code !== 0) {
        this.$message.error(res.msg)
      }
      for (var i = 0; i < res.data.list.length; i++) {
        res.data.list[i].title = res.data.list[i].title.replace('【负面】', '<el-tag size="mini" effect="dark" style="background-color: #ED9090;border-color:#ED9090;height:19px;padding:0 3px;border-radius:2px;color: #FFF !important;margin-right: 5px">负</el-tag>')
      }
      this.tableData = res.data.list
      this.count = parseInt(res.data.count)
      this.loading = false
    }
  },
  mounted () {
    this.getListIntelligence()
  }
}
</script>

<style lang="scss">
  .intelligence-send {
    height: 100%;

    .filter {
      background: #fff;
      box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      padding: 15px 25px;
      &>div:not(:last-child){
        border-bottom: 1px solid #efefef;
      }
      .title{
        padding: 8px 0;
        color: #565656;
        font-size: 15px;
        display: inline-block;
        .el-radio--mini.is-bordered .el-radio__label,
        .el-checkbox__input.is-checked + .el-checkbox__label{
          color: #838383;
        }
        .el-input,.el-select{
          width: 230px;
          margin-right: 20px;
        }
        .el-radio.is-bordered.is-checked{
          border: 1px solid #3472c7;
        }
        .el-radio__input.is-checked + .el-radio__label{
          color: #3472c7;
        }
      }

      .dateContainer {
        color: #000000;
        font-size: 15px;
      }

      .beginTime,
      .endTime {
        font-size: 12px;
      }

      .el-date-editor {
        position: absolute;
        left: 400px;
        bottom: -33px;
        z-index: 1;
      }
      .el-date-editor.el-input__inner{
        height: 35px !important;
      }

      &.botton-item {
        .el-form-item__content {
          margin-left: 0px !important;
          padding-left: 0px;

          .el-button {
            height: 24px;
            padding: 5px 15px;
            margin-left: 20px;
          }
        }
      }

      .el-radio.is-bordered {
        margin-right: 0px;

        .el-radio__input {
          display: none;
        }
      }

      .el-input {
        width: 300px;
      }

      .search-btn {
        height: 24px;
        padding: 5px 15px;
        float: right;
        margin-top: 8px;
      }

      .el-radio {
        padding: 6px 15px 0 15px;

        .el-radio__label {
          padding-left: 0;
        }
      }
    }

    .content {
      width: 100%;
      min-height: calc(100% - 143px);
      background: #fff;
      box-shadow: 2px 2px 10px 0px rgba(207, 207, 207, 0.5);
      border-radius: 2px;
      margin-top: 20px;
      padding: 20px;
      position: relative;

      .el-table {
        margin-top: 30px;
        margin-bottom: 50px;
        .dataTitle {
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }

      .list-bottom {
        display: flex;
        justify-content: space-between;
        position: absolute;
        bottom: 20px;
        left: 20px;
        right: 20px;

        .count {
          line-height: 32px;
        }
      }

      .details-wrap {
        border-radius: 10px;

        .el-dialog__header {
          border-bottom: 1px solid #D7D7D7;

          .el-dialog__title {
            font-size: 20px;
          }
        }

        .el-dialog__body {
          .title {
            color: #333333;
            font-size: 24px;
          }

          .details {
            padding: 20px;
            border: 1px solid #D7D7D7;
            border-radius: 10px;
            margin-top: 16px;

            p {
              margin: 0;
              margin-bottom: 5px;
              font-size: 16px;
              color: #333;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
</style>
