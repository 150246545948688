import request from '@/utils/request'

export function listIntelligence (data) {
  return request({
    url: '/intelligence/listIntelligence',
    method: 'POST',
    data: data
  })
}
export function conditionQueryIntelligence (data) {
  return request({
    url: '/intelligence/queryIntelligence',
    method: 'POST',
    data: data
  })
}
export function backoutIntelligence (data) {
  return request({
    url: '/intelligence/backoutIntelligence',
    method: 'POST',
    data: data
  })
}
export function queryIntelligenceDetail (data) {
  return request({
    url: '/intelligence/queryIntelligenceDetail',
    method: 'POST',
    data: data
  })
}
